var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12" },
    [
      _c("v-card-title", [
        _vm._v(" Details User " + _vm._s(_vm.fullName) + " "),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Full Name", readonly: "" },
                        model: {
                          value: _vm.fullName,
                          callback: function ($$v) {
                            _vm.fullName = $$v
                          },
                          expression: "fullName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Email", readonly: "" },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("pga-select", {
                        attrs: {
                          "item-value": "Id",
                          "item-text": "Name",
                          items: _vm.roles,
                          label: "Roles",
                          multiple: "",
                          readonly: "",
                        },
                        model: {
                          value: _vm.roles,
                          callback: function ($$v) {
                            _vm.roles = $$v
                          },
                          expression: "roles",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("pga-text-field", {
                        attrs: {
                          "prepend-icon": "mdi-calendar",
                          label: "Create Date",
                          readonly: "",
                        },
                        model: {
                          value: _vm.createDate,
                          callback: function ($$v) {
                            _vm.createDate = $$v
                          },
                          expression: "createDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Tenant", readonly: "" },
                        model: {
                          value: _vm.tenantName,
                          callback: function ($$v) {
                            _vm.tenantName = $$v
                          },
                          expression: "tenantName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }