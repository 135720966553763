var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditUser" } },
            [_c("pga-save-button", { on: { click: _vm.Edit } })],
            1
          ),
          _c(
            "pga-authorized-content",
            { attrs: { permission: "DeleteUser" } },
            [_c("pga-delete-button", { on: { click: _vm.ShowDialogDelete } })],
            1
          ),
          _c("pga-cancel-button", { on: { click: _vm.cancel } }),
        ],
        1
      ),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.UserEdit.Command } },
        [
          _c("pga-user-edit", {
            model: {
              value: _vm.UserEdit.Command,
              callback: function ($$v) {
                _vm.$set(_vm.UserEdit, "Command", $$v)
              },
              expression: "UserEdit.Command",
            },
          }),
          _c("pga-dialog", {
            attrs: {
              title: "Deleting User",
              "icon-title": "mdi-delete-alert",
              message:
                "Are you sure you want to delete the user '" +
                _vm.fullName +
                "'?",
              color: "orange",
              "icon-button": "mdi-checkbox-marked-circle-outline",
            },
            on: { agree: _vm.Delete, reject: _vm.HideDialog },
            model: {
              value: _vm.showDialog,
              callback: function ($$v) {
                _vm.showDialog = $$v
              },
              expression: "showDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }