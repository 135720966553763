var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditUser" } },
            [_c("pga-save-button", { on: { click: _vm.Create } })],
            1
          ),
          _c("pga-cancel-button", { on: { click: _vm.cancel } }),
        ],
        1
      ),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.UserCreate.Command } },
        [
          _c("pga-user-create", {
            model: {
              value: _vm.UserCreate.Command,
              callback: function ($$v) {
                _vm.$set(_vm.UserCreate, "Command", $$v)
              },
              expression: "UserCreate.Command",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }