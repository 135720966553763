var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditUser" } },
            [_c("pga-edit-button", { on: { click: _vm.Edit } })],
            1
          ),
        ],
        1
      ),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.UserDetails } },
        [
          _c("pga-user-details", {
            model: {
              value: _vm.UserDetails,
              callback: function ($$v) {
                _vm.UserDetails = $$v
              },
              expression: "UserDetails",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }