var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Users",
      headers: _vm.headers,
      data: _vm.UsersList,
      loading: _vm.isLoading,
      "col-props": ["Roles"],
    },
    on: { "row-clicked": _vm.rowClicked },
    scopedSlots: _vm._u([
      {
        key: "column-Roles",
        fn: function (props) {
          return [
            _c(
              "pga-partial-content",
              {
                attrs: {
                  "content-length": props.row.item.Roles.length,
                  chips: "",
                },
              },
              _vm._l(props.row.item.Roles.slice(0, 3), function (t, i) {
                return _c("v-chip", { key: i }, [
                  _vm._v(" " + _vm._s(t.Name) + " "),
                ])
              }),
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }